import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import twitter from '../assets/twitter-.png';
import teleg from '../assets/telegram.png';
import dexview from '../assets/dexview.png';
import bsc from '../assets/bsc.png';
import coingecko from '../assets/coingeko.png';
import coinmarket from '../assets/coinmarketcap.png';
import pinksale from '../assets/pinksale.png';
 
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

const dexS = () => {
    window.open("https://www.dexview.com/");
}

const coin = () => {
    window.open("https://www.coingecko.com/");
}

const coinM = () => {
    window.open("https://coinmarketcap.com/");
}

const bscL = () => {
    window.open("https://bscscan.com/");
}

const pinksaleL = () => {
    window.open("https://www.pinksale.finance/");
}

class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">

                    <div class="conT4">OUR TOP PARTNERS</div>

                    <div class="partnerDiv">
                        <img onClick={bscL} src={bsc} />
                        <img onClick={coin} src={coingecko} />
                        <img onClick={coinM} src={coinmarket} />
                    </div>

                    <div class="partnerDiv">
                        <img onClick={dexS} src={dexview} />
                         <img onClick={pinksaleL} src={pinksale} />
                    </div>

                    <p class="punkSubH">Join the FROG community and be part of the future of decentralized finance. With FROG, you're not just investing in a token—you're supporting a vision of innovation, inclusivity, and growth. Dive in today and discover the endless possibilities that FROG has to offer! 🚀</p>

                    <div class="glitch-wrapper">
                        <div class="glitch" data-text="FROG" onClick={() => window.location.href = '/'}><span className='hl2-2'>FROG</span></div>
                    </div>

                    <div class="icons">
                        <img onClick={tweet} src={twitter} />
                        <img onClick={tg} src={teleg} />
                    </div>

                    <div class="copyright">Copyright © 2024 FROG. All Rights Reserved</div>


                </div>



            </div>
        )
    }
}

export default Footer;

